import React from 'react';
import { CardCarousel, HeadingWithParagraph, WorkoutCard } from 'src/components/shared';
import * as Styles from 'src/styles/heading-paragraph.module.scss';
import { IWorkoutFields } from '@pvolve/sdk/src/models/contentful_types';
import { Grid } from 'semantic-ui-react';
interface ContentSlideProps {
    phaseName: string;
    id: string;
    title: string;
    subtitle: string;
    paragraph: string;
    additionalInfo?: {
        title: string;
        paragraph: string;
    };
    content: Array<IWorkoutFields>;
    carouselTitle: string;
}

const WorkoutCardWithData = ({ data }, index: number) => (
    <WorkoutCard workout={data} key={`workout-card-${index}`} />
);

const ContentSlide = ({
    phaseName,
    id,
    title,
    subtitle,
    paragraph,
    additionalInfo,
    content,
    carouselTitle,
}: ContentSlideProps) => (
    <div key={id} className="justifyContent-center display-column">
        <Grid className="justifyContent-center">
            <div className="ui text container" key={id}>
                <HeadingWithParagraph
                    headingSize="h2"
                    headingClass="margin--0"
                    headingLabel={title}
                    headingWeight="bold"
                    headingTransform="upper"
                    pLabel={paragraph}
                    pClass="p1"
                    alignment="vertical"
                    secondaryHeadingLabel={subtitle}
                    secondaryHeadingClass="accent lower display--block margin-bottom--small"
                />
                {additionalInfo && (
                    <div className={Styles.contentMoreInfo}>
                        <HeadingWithParagraph
                            headingSize="h4"
                            headingClass="margin--0"
                            headingLabel={additionalInfo.title}
                            headingWeight="bold"
                            headingTransform="upper"
                            pLabel={additionalInfo.paragraph}
                            pClass="p1"
                            alignment="vertical"
                        />
                    </div>
                )}
            </div>
        </Grid>
        <div>
            <CardCarousel
                title={carouselTitle}
                to={`/series/chapters/${phaseName}-phase/`}
                data={content}
                renderItem={WorkoutCardWithData}
                naturalSlideWidth={300}
            />
        </div>
    </div>
);

export default ContentSlide;
