import React from 'react';
import { Grid } from 'semantic-ui-react';
import { HeadingWithParagraph, WidgetIcon } from 'src/components/shared';
import * as Styles from 'src/styles/heading-paragraph.module.scss';

interface ContentWidgetProps {
    phaseName?: string;
    id: string;
    title: string;
    subtitle: string;
    paragraph: string;
    additionalInfo?: {
        title: string;
        paragraph: string;
    };
    displayLinks?: boolean;
}

const ContentWidget = ({
    phaseName,
    id,
    title,
    subtitle,
    paragraph,
    additionalInfo,
    displayLinks = false,
}: ContentWidgetProps) => {
    const phaseInfo = [
        {
            icon: 'pv-foods',
            heading: 'About to go grocery shopping?',
            linkText: `${phaseName} foods`,
            text: 'Be sure to pick up some',
            type: 'foods',
            url: `/foods/${phaseName}`,
        },
        {
            icon: 'pv-meals',
            heading: 'Need recipe inspiration?',
            linkText: `${phaseName} meals`,
            text: 'Start cooking these',
            type: 'meals',
            url: `/meals/${phaseName}`,
        },
    ];

    return (
        <Grid className="justifyContent-center">
            <div key={id}>
                <div className="ui text container" key={id}>
                    <HeadingWithParagraph
                        headingSize="h2"
                        headingClass="margin--0"
                        headingLabel={title}
                        headingWeight="bold"
                        headingTransform="upper"
                        pLabel={paragraph}
                        pClass="p1"
                        alignment="vertical"
                        secondaryHeadingLabel={subtitle}
                        secondaryHeadingClass="h1 accent lower display--block margin-bottom--small"
                    />
                    {additionalInfo && (
                        <div className={Styles.contentMoreInfo}>
                            <HeadingWithParagraph
                                headingSize="h4"
                                headingClass="margin--0"
                                headingLabel={additionalInfo.title}
                                headingWeight="bold"
                                headingTransform="upper"
                                pLabel={additionalInfo.paragraph}
                                pClass="p1 margin-top--small"
                                alignment="vertical"
                            />
                        </div>
                    )}
                    {displayLinks && (
                        <Grid.Row className="margin-top--large">
                            <Grid.Column className="margin-top--small">
                                {phaseInfo.map((item, i) => (
                                    <WidgetIcon info={item} key={`${item.type}-${i}`} />
                                ))}
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </div>
            </div>
        </Grid>
    );
};

export default ContentWidget;
