import React from 'react';
import ContentWidget from '../components/ContentWidget';
interface HormonesSectionProps {
    title: string;
    subtitle: string;
    paragraph: string;
    imageUrl: string;
    id: string;
}

const HormonesSection = ({ title, subtitle, paragraph, imageUrl, id }: HormonesSectionProps) => (
    <div className="margin-vertical--large display-column alignItems--center">
        <ContentWidget title={title} subtitle={subtitle} paragraph={paragraph} id={id} />
        <div className="margin-top--large" style={{ width: '100%' }}>
            <img src={imageUrl} style={{ width: '100%' }} alt="Hormones graphic" />
        </div>
    </div>
);

export default HormonesSection;
