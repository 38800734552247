import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Selectors from 'src/state/root-selectors';
import PhasePageHeader from '../components/PhasePageHeader';
import { Split, FooterLinkRight } from 'src/components/ui';
import ContentWidget from '../components/ContentWidget';
import ContentSlide from '../components/ContentSlide';
import HormonesSection from '../components/HormonesSection';
import { UpdateMyResponses } from 'src/components/shared/UpdateMyResponses';
import { Icon, ProgressBar, PhasesLength, Loader } from 'src/components/shared';
import { getDynamicContent } from 'src/utils/sdk-utils';
import { getPhaseLengthData, phasesDefaultState } from '../utils/enrolledUtils';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import { Button } from 'semantic-ui-react';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import * as Styles from 'src/styles/phase-enrolled-layout.module.scss';
import classNames from 'classnames';
import Layout from 'src/components/layout/Layout';

import { notifyBugsnagError } from 'src/utils/bugsnag';

interface PhaseEnrolledLayoutProps {
    phaseName: string;
    data: any;
}
interface Enrolled {
    nextPhase: string;
    prevPhase: string;
    subtitle: string;
    title: string;
    sections: Array<{
        id: string;
        imageUrl: string;
        paragraph: string;
        subtitle: string;
        title: string;
        additionalInfo: {
            title: string;
            paragraph: string;
        };
    }>;
}

const initialState = {
    nextPhase: '',
    prevPhase: '',
    subtitle: '',
    title: '',
    sections: [],
};

const PhaseEnrolledLayout = ({ phaseName, data }: PhaseEnrolledLayoutProps) => {
    const {
        allContentfulSeries: { edges },
    } = data;
    const [pageContent, setPageContent] = useState<Enrolled>(initialState);
    const [loading, setLoading] = useState(true);
    const phaseAttributes = useSelector(Selectors.phases.currentPhases);
    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.lg, Directions.down);

    const currentEnrollment = useSelector((state) =>
        PvolveSelectors.series.enrollment.current(state)
    );
    const enrollmentID = currentEnrollment?.series_id;
    const variants = useSelector(Selectors.flags.phaseAndFunction);
    const pnfID = variants.phaseAndFunctionSeries?.variant.payload.series[0];
    const pnfSeriesDetails = !!pnfID && edges.find((e) => e?.node?.contentful_id == pnfID);
    const updateUrlParam = pnfSeriesDetails?.node?.questionnaire?.updateUrlParam;
    const isEnrolledInPnF = enrollmentID === pnfID;

    const phaseLengthData = getPhaseLengthData(phaseName, phaseAttributes?.phases, isEnrolledInPnF);
    let phaseAttributesUpdated = { ...phaseAttributes };
    if (!isEnrolledInPnF) {
        phaseAttributesUpdated.phases?.map((phase, index) => {
            phase.days = phasesDefaultState[index].days;
            phase.end.day = phasesDefaultState[index].end.day;
            phase.start.day = Number(phasesDefaultState[index].start.day);
        });
    }

    const workoutsByPhase = data[phaseName]?.workouts;
    const workouts = workoutsByPhase.filter((workout: any) => !!workout.type === false);

    useEffect(() => {
        let isMounted = true;
        const getContent = async (phaseName: string) => {
            try {
                setLoading(true);
                const phaseCapitalize = phaseName.charAt(0).toUpperCase() + phaseName.slice(1);
                const content = await getDynamicContent(
                    `PhaseAndFunction.PhaseDetails.${phaseCapitalize}`
                );
                if (content && content.length > 0) {
                    if (isMounted) {
                        const pageContentResponse = content[0].value;
                        setPageContent(pageContentResponse);
                        setLoading(false);
                    }
                }
            } catch (err) {
                notifyBugsnagError(err);

                console.log({ err });
                return {};
            }
        };

        getContent(phaseName);

        return () => {
            isMounted = false;
        };
    }, [phaseName]);

    const pageContext = {
        theme: {},
    };

    return (
        <Layout pageContext={pageContext}>
            {loading ? (
                <div className="margin-top--large margin-bottom--large">
                    <Loader />
                </div>
            ) : (
                <div className={Styles.phaseEnrolled}>
                    <PhasePageHeader
                        title={pageContent?.title}
                        subtitle={pageContent?.subtitle}
                        nextPhase={pageContent?.nextPhase}
                        prevPhase={pageContent?.prevPhase}
                        page={'more-about'}
                    >
                        <div className={Styles.phaseEnrolledProgress}>
                            {!!phaseAttributes && (
                                <div className="margin-top--small">
                                    <ProgressBar
                                        phaseAttributes={phaseAttributesUpdated}
                                        highlightedPhase={phaseName}
                                        displayIndicator={isEnrolledInPnF}
                                    />
                                </div>
                            )}
                            <div
                                className={classNames(
                                    'margin-top--small',
                                    Styles.phaseEnrolledHeaderContent
                                )}
                            >
                                <PhasesLength
                                    phaseName={phaseName}
                                    header={'based on your cycle:'}
                                    fromDay={phaseLengthData.start}
                                    toDay={phaseLengthData.end}
                                    Styles={Styles}
                                />
                                {isEnrolledInPnF && !!updateUrlParam && (
                                    <UpdateMyResponses
                                        to={`phase-and-function/${updateUrlParam}`}
                                        iconSize={20}
                                        className="margin-bottom--xsmall"
                                    />
                                )}
                            </div>
                        </div>
                    </PhasePageHeader>
                    <div className={Styles.phaseEnrolledMain}>
                        <div className={Styles.phaseEnrolledHormones}>
                            <HormonesSection
                                id={pageContent?.sections[0]?.id}
                                title={pageContent?.sections[0]?.title}
                                subtitle={pageContent?.sections[0]?.subtitle}
                                paragraph={pageContent?.sections[0]?.paragraph}
                                imageUrl={pageContent?.sections[0]?.imageUrl}
                            />
                            {isTabletPortraitOrBelow && (
                                <div className="justifyContent-center">
                                    <Button
                                        className={`${Styles.enlargeButton} margin--0 padding--0 alignItems--center`}
                                        as={LinkWithPrevPath}
                                        to={`/more-about/${phaseName}/chart`}
                                        secondary
                                    >
                                        <Icon
                                            name="pv-expand"
                                            className="margin-right--xsmall"
                                            size={14}
                                        />
                                        <span className="tiny">Enlarge</span>
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className={Styles.phaseEnrolledSeparator}>
                            <Split>
                                <Icon name="pv-mind" size={83} />
                            </Split>
                        </div>
                        <ContentWidget
                            id={pageContent?.sections[1]?.id}
                            title={pageContent?.sections[1]?.title}
                            subtitle={pageContent?.sections[1]?.subtitle}
                            paragraph={pageContent?.sections[1]?.paragraph}
                        />
                        <div className={Styles.phaseEnrolledSeparator}>
                            <Split>
                                <Icon name="pv-movement" size={83} />
                            </Split>
                        </div>
                        <div>
                            {!!workouts && workouts.length > 0 && (
                                <ContentSlide
                                    id={pageContent?.sections[2]?.id}
                                    title={pageContent?.sections[2]?.title}
                                    subtitle={pageContent?.sections[2]?.subtitle}
                                    paragraph={pageContent?.sections[2]?.paragraph}
                                    additionalInfo={pageContent?.sections[2]?.additionalInfo}
                                    content={workouts}
                                    carouselTitle={`${phaseName} LIBRARY`}
                                    phaseName={phaseName}
                                />
                            )}
                        </div>
                        <div className={Styles.phaseEnrolledSeparator}>
                            <Split>
                                <Icon name="pv-meals" size={83} />
                            </Split>
                        </div>
                        <ContentWidget
                            id={pageContent?.sections[3]?.id}
                            title={pageContent?.sections[3]?.title}
                            subtitle={pageContent?.sections[3]?.subtitle}
                            paragraph={pageContent?.sections[3]?.paragraph}
                            additionalInfo={pageContent?.sections[3]?.additionalInfo}
                            phaseName={phaseName}
                            displayLinks
                        />
                    </div>
                    <div className={Styles.phaseEnrolledFooter}>
                        <FooterLinkRight
                            to="/sources/phase-and-function"
                            label="Sources & additional reading"
                            linkClass="h6 bold upper margin--0"
                        />
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default PhaseEnrolledLayout;
