import React from 'react';
import { Router } from '@reach/router';
import { graphql } from 'gatsby';
import PrivateRoute from 'src/components/PrivateRoute';
import {
    PhaseEnrolledLayout,
    PhaseImageFullWidth,
} from 'src/components/phase-and-function/phases/layouts';

import NotFoundPage from 'src/pages/404';

const PhaseEnrolled = ({ data, location }) => {
    const phaseNameProps = {};
    const prevPath = location?.state?.prevPath;

    if (prevPath) {
        phaseNameProps.prevPath = prevPath;
        phaseNameProps.replace = true;
    }

    return (
        <Router>
            <PrivateRoute
                path="/more-about/:phaseName"
                component={PhaseEnrolledLayout}
                data={data}
                {...phaseNameProps}
            />
            <PrivateRoute
                path="/more-about/:phaseName/:chart"
                component={PhaseImageFullWidth}
                {...phaseNameProps}
            />
            <NotFoundPage default />
        </Router>
    );
};

// TODO: The hardcoded slug ids used bellow would break data if a change is done directly on Contentful
// Needs update query to allContentfulSeriesChapters and filter data programmatically
export const query = graphql`
    query MoreAboutPageQuery {
        menstrual: contentfulSeriesChapter(slug: { eq: "menstrual-phase" }) {
            workouts {
                ...ContentfulWorkout_Card
            }
        }
        follicular: contentfulSeriesChapter(slug: { eq: "follicular-phase" }) {
            workouts {
                ...ContentfulWorkout_Card
            }
        }
        ovulatory: contentfulSeriesChapter(slug: { eq: "ovulatory-phase" }) {
            workouts {
                ...ContentfulWorkout_Card
            }
        }
        luteal: contentfulSeriesChapter(slug: { eq: "luteal-phase" }) {
            workouts {
                ...ContentfulWorkout_Card
            }
        }
        allContentfulSeries {
            edges {
                node {
                    contentful_id
                    ...ContentfulSeries_Card
                }
            }
        }
    }
`;

export default PhaseEnrolled;
