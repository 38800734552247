import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import { SquareCloseButton } from 'src/components/shared';
import { getDynamicContent } from 'src/utils/sdk-utils';
import { navigate } from 'gatsby';
import * as Styles from 'src/styles/phase-image-fullwidth.module.scss';

const PhaseImageFullWidth = () => {
    const { ...params } = useParams();
    const [loading, setLoading] = useState(true);
    const [imgUrl, setImgUrl] = useState('');
    const phaseName = params.phaseName;

    useEffect(() => {
        let isMounted = true;
        const getContent = async (phaseName: string) => {
            try {
                setLoading(true);
                const phaseCapitalize = phaseName.charAt(0).toUpperCase() + phaseName.slice(1);
                const content = await getDynamicContent(
                    `PhaseAndFunction.PhaseDetails.${phaseCapitalize}`
                );
                if (content && content.length > 0) {
                    if (isMounted) {
                        const pageContentResponse = content[0].value.sections[0].imageUrl;
                        setImgUrl(pageContentResponse);
                        setLoading(false);
                    }
                } else {
                    navigate('/404');
                }
            } catch (err) {
                console.log({ err });
                return {};
            }
        };

        getContent(phaseName);

        return () => {
            isMounted = false;
        };
    }, [phaseName]);

    return (
        <div className={Styles.phaseImageFullWidth}>
            <SquareCloseButton />
            {!loading && imgUrl != '' && (
                <div className={Styles.imgWrapper}>
                    <img src={imgUrl} />
                </div>
            )}
        </div>
    );
};

export default PhaseImageFullWidth;
